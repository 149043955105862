<template>
  <div class="register-step register-complete">
      <div class="auth-form-heading">
        <p class="auth-form-title">COMPLETE OS SEUS DADOS</p>
        <p class="auth-form-subtitle">Gostavamos de o conhecer melhor por isso complete os seguintes dados</p>
      </div>
      <div class="auth-form-wrapper">
        <FormulateForm name="register-complete" action="" class="auth-form register-complete-form" v-model="values" @submit="registerCompleteHandler">
          <div class="box-form-group">
            <p class="box-form-group-title">Dados Pessoais</p>
            <div class="row box-form-row">
              <div class="col-12 col-lg-6 box-form-column">
                <div class="form-field">
                  <FormulateInput type="text" name="city" v-model="city" validation="required" placeholder="Insira a sua cidade" label="Cidade" />
                </div>
              </div>
              <div class="col-12 col-lg-6 box-form-column">
                <div class="form-field">
                  <FormulateInput type="select" name="language" v-model="language" validation="required" placeholder="Selecione a seu idioma" label="Idioma"  :options="{pt: 'Português', en: 'Ingles', es: 'Espanhol'}" />
                </div>
              </div>
              <div class="col-12 col-lg-6 box-form-column">
                <div class="form-field">
                  <FormulateInput type="date" name="birthdate" v-model="birthdate" validation="required" placeholder="" label="Data de Nascimento" :max="maxBirthdate" />
                </div>
              </div>
              <div class="col-12 col-lg-6 box-form-column">
                <div class="form-field">
                  <FormulateInput type="text" name="phone" v-model="phone" validation="required" placeholder="Insira o seu telefone" label="Telefone" />
                </div>
              </div>
            </div>
          </div>
          <div class="box-form-group">
            <p class="box-form-group-title">Perfil</p>
            <div class="row box-form-row">
              <div class="col-12 col-lg-6 box-form-column">
                <div class="form-field">
                  <FormulateInput type="text" name="weight" v-model="weight" validation="required" placeholder="50" label="Peso" />
                  <span class="form-field-caption">kg</span>
                </div>
              </div>
              <div class="col-12 col-lg-6 box-form-column">
                <div class="form-field">
                  <FormulateInput type="text" name="height" v-model="height" validation="required" placeholder="1.00" label="Altura" />
                  <span class="form-field-caption">m</span>
                </div>
              </div>
            </div>
          </div>
          <div class="form-message-response-wrapper message-error" v-if="showMessage && !submitted">
            <p>{{ formResponseMessage }}</p>
          </div>
          <button type="submit" class="generic-btn red-gradient auth-form-submit">Seguinte</button>
        </FormulateForm>
      </div>
  </div>
</template>

<script>
import LogoBoxSVG from "@/components/svg/logo.vue";
import emailIcon from "@/components/svg/mail.vue";
import lockIcon from "@/components/svg/lock.vue";
import chevronLeft from "@/components/svg/chevron-left.vue";
import {EventBus} from "@/event-bus";
import moment from 'moment';

export default {
  name: "auth-register-complete",
  data() {
    return {
      values: {},
      errors: [],
      submitted: false,
      city: '',
      language: '',
      birthdate: '',
      phone: '',
      weight: '',
      height: '',
      showMessage: false,
      formResponseMessage: '',
      maxBirthdate: '2021-09-01',
    };
  },
  components : {
    LogoBoxSVG,
    emailIcon,
    lockIcon,
    chevronLeft
  },
  methods: {
    registerCompleteHandler: function() {

      this.height = this.height.replace(",", "");

      this.$root.registerFormInfo.city = this.city;
      this.$root.registerFormInfo.language = this.language;
      this.$root.registerFormInfo.birthdate = this.birthdate;
      this.$root.registerFormInfo.phone = this.phone;
      this.$root.registerFormInfo.weight = this.weight;
      this.$root.registerFormInfo.height = this.height;

      //EventBus.$emit("bus-handleRegisterObjectives");
      EventBus.$emit("bus-handleRegisterPlans");
    }
  },
  created() {
    let now = moment();
    this.maxBirthdate = now.subtract(18, "years").format('YYYY-MM-DD');
  }
}
</script>