<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 24 16">
    <path fill="#FFF" d="M44.234 36.088H24.13c-.15 0-.278-.125-.278-.274v-11.68l9.873 6.36c.139.09.297.135.457.135.162 0 .323-.046.463-.138l9.867-6.482v11.805c0 .15-.127.274-.278.274zm-1.767-12.712l-8.29 5.446-8.451-5.446h16.741zm2.718-1.39c-.283-.156-.604-.254-.951-.254H24.13c-1.074 0-1.948.86-1.948 1.918v12.164c0 1.058.874 1.918 1.948 1.918h20.104c1.073 0 1.948-.86 1.948-1.918V23.65c0-.548-.238-1.04-.613-1.39-.102-.13-.235-.222-.384-.273z" transform="translate(-42 -296) translate(19.818 274.268)"/>
  </svg>
</template>

<script>
export default {
  name: 'mail'
}
</script>
